import styled from 'styled-components'
import { Card, Flex } from '@zinuswap/uikit'

const StyledCard = styled(Card)`
  align-self: baseline;
  max-width: 100%;
  margin: 0 0 24px 0;

  ${({ theme }) => theme.mediaQueries.sm} {
    max-width: 410px;
    margin: 0 12px 24px;
  }
`

const CardInnerContainer = styled(Flex)`
  flex-direction: column;
  justify-content: space-around;
  padding: 30px 24px;
`

const HomeCard: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <StyledCard>
      <CardInnerContainer>
        {children}
      </CardInnerContainer>
    </StyledCard>
  )
}

export default HomeCard
